.aboutCompany {
    min-height: 100vh;
    width: 100vw;
    margin: 0%;
    padding: 0%;
    background-image: repeating-linear-gradient(
            135deg,
            rgba(172, 176, 216, 0.08) 0px,
            rgba(172, 176, 216, 0.08) 1px,
            transparent 1px,
            transparent 9px
        ),
        repeating-linear-gradient(
            45deg,
            rgba(172, 176, 216, 0.08) 0px,
            rgba(172, 176, 216, 0.08) 1px,
            transparent 1px,
            transparent 9px
        ),
        linear-gradient(90deg, rgb(183, 70, 156), rgb(8, 33, 155));
}

/* .aboutCompany::before {
    content: '';
    background-image: url('./svg/waveTop.svg');
    display: block;
    height: 7rem;
    overflow: hidden;
    line-height: 0;
    width: 100%;
    background-position: top;
    transform: rotate(180deg);
} */

/* .aboutCompany::after {
    content: '';
    background-image: url('./svg/wavesBot.svg');
    display: block;
    height: 7rem;
    overflow: hidden;
    line-height: 0;
    width: 100%;
    background-position: bottom;
} */

.aboutCompany section {
    padding: 5rem 0;
}

.aboutCompany .spacerTop {
    background-image: url('./svg/waveTop.svg');
    display: block;
    height: 7rem;
    overflow: hidden;
    line-height: 0;
    width: 100%;
    background-position: top;
    transform: rotate(180deg);
}

.aboutCompany .spacerBottom {
    background-image: url('./svg/wavesBot.svg');
    display: block;
    height: 7rem;
    overflow: hidden;
    line-height: 0;
    width: 100%;
    background-position: bottom;
    transform: rotate(180deg);
}
