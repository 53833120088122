.aboutMe {
    height: 100vh;
    width: 100vw;
    margin: 0%;
    padding: 0%;
    background-image: radial-gradient(
            circle at 67% 83%,
            hsla(317, 0%, 96%, 0.05) 0%,
            hsla(317, 0%, 96%, 0.05) 1%,
            transparent 1%,
            transparent 5%,
            transparent 5%,
            transparent 100%
        ),
        radial-gradient(
            circle at 24% 80%,
            hsla(317, 0%, 96%, 0.05) 0%,
            hsla(317, 0%, 96%, 0.05) 27%,
            transparent 27%,
            transparent 63%,
            transparent 63%,
            transparent 100%
        ),
        radial-gradient(
            circle at 23% 5%,
            hsla(317, 0%, 96%, 0.05) 0%,
            hsla(317, 0%, 96%, 0.05) 26%,
            transparent 26%,
            transparent 82%,
            transparent 82%,
            transparent 100%
        ),
        radial-gradient(
            circle at 21% 11%,
            hsla(317, 0%, 96%, 0.05) 0%,
            hsla(317, 0%, 96%, 0.05) 35%,
            transparent 35%,
            transparent 45%,
            transparent 45%,
            transparent 100%
        ),
        radial-gradient(
            circle at 10% 11%,
            hsla(317, 0%, 96%, 0.05) 0%,
            hsla(317, 0%, 96%, 0.05) 21%,
            transparent 21%,
            transparent 81%,
            transparent 81%,
            transparent 100%
        ),
        radial-gradient(
            circle at 19% 61%,
            hsla(317, 0%, 96%, 0.05) 0%,
            hsla(317, 0%, 96%, 0.05) 20%,
            transparent 20%,
            transparent 61%,
            transparent 61%,
            transparent 100%
        ),
        radial-gradient(
            circle at 13% 77%,
            hsla(317, 0%, 96%, 0.05) 0%,
            hsla(317, 0%, 96%, 0.05) 63%,
            transparent 63%,
            transparent 72%,
            transparent 72%,
            transparent 100%
        ),
        radial-gradient(
            circle at 30% 93%,
            hsla(317, 0%, 96%, 0.05) 0%,
            hsla(317, 0%, 96%, 0.05) 33%,
            transparent 33%,
            transparent 82%,
            transparent 82%,
            transparent 100%
        ),
        linear-gradient(318deg, rgb(22, 176, 207), rgb(103, 7, 215));
}

.aboutMe::before {
    content: '';
    background-image: url('./svg/waveTop.svg');
    display: block;
    height: 10%;
    overflow: hidden;
    line-height: 0;
    width: 100%;
    background-position: top;
}

.aboutMe::after {
    content: '';
    background-image: url('./svg/waveBot.svg');
    display: block;
    height: 10%;
    overflow: hidden;
    line-height: 0;
    width: 100%;
    background-position: bottom;
}

.aboutMe .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 80%;
}
