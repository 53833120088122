html {
    font-size: 12px;
}

body {
    overflow-x: hidden;
    overflow-y: auto !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Kaisei HarunoUmi', serif;
}

p {
    font-family: 'Roboto', sans-serif;
}

@media (min-width: 576px) {
    html {
        font-size: 13px;
    }
}

@media (min-width: 768px) {
    html {
        font-size: 14px;
    }
}

@media (min-width: 992px) {
    html {
        font-size: 15px;
    }
}

@media (min-width: 1200px) {
    html {
        font-size: 16px;
    }
}

@media (min-width: 1400px) {
    html {
        font-size: 16px;
    }
}
