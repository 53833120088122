.cardModule {
    max-width: 30rem;
    position: relative;
    backdrop-filter: blur(0.1rem);
}

.cardModule.left::after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1000;
    border-style: solid;
    border-color: transparent rgba(255, 255, 255, 0.1);
    border-width: 1.25rem 0 1.25rem 1.25rem;
    top: 50%;
    right: calc(-1.25rem + -1px);
    margin-top: -1.25rem;
}

.cardModule.right::after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1000;
    border-style: solid;
    border-color: transparent rgba(255, 255, 255, 0.1);
    border-width: 1.25rem 1.25rem 1.25rem 0;
    top: 50%;
    left: calc(-1.25rem + -1px);
    margin-top: -1.25rem;
}

.cardModule p {
    margin: 0px;
}

@media (min-width: 576px) {
    .cardModule {
        max-width: 30rem;
    }
}

@media (min-width: 768px) {
    .cardModule {
        max-width: 30rem;
    }
}

@media (min-width: 992px) {
    .cardModule {
        max-width: 30rem;
    }
}

@media (min-width: 1200px) {
    .cardModule {
        max-width: 30rem;
    }
}

@media (min-width: 1400px) {
    .cardModule {
        max-width: 30rem;
    }
}
