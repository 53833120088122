.services {
    height: 100vh;
    width: 100vw;
    margin: 0%;
    padding: 0%;
    background-image: repeating-linear-gradient(
            0deg,
            hsla(284, 43%, 48%, 0.05) 0px,
            hsla(284, 43%, 48%, 0.05) 1px,
            transparent 1px,
            transparent 11px,
            hsla(284, 43%, 48%, 0.05) 11px,
            hsla(284, 43%, 48%, 0.05) 12px,
            transparent 12px,
            transparent 32px
        ),
        repeating-linear-gradient(
            135deg,
            hsla(284, 43%, 48%, 0.05) 0px,
            hsla(284, 43%, 48%, 0.05) 1px,
            transparent 1px,
            transparent 11px,
            hsla(284, 43%, 48%, 0.05) 11px,
            hsla(284, 43%, 48%, 0.05) 12px,
            transparent 12px,
            transparent 32px
        ),
        repeating-linear-gradient(
            0deg,
            hsla(284, 43%, 48%, 0.05) 0px,
            hsla(284, 43%, 48%, 0.05) 1px,
            transparent 1px,
            transparent 11px,
            hsla(284, 43%, 48%, 0.05) 11px,
            hsla(284, 43%, 48%, 0.05) 12px,
            transparent 12px,
            transparent 32px
        ),
        repeating-linear-gradient(
            45deg,
            hsla(284, 43%, 48%, 0.05) 0px,
            hsla(284, 43%, 48%, 0.05) 1px,
            transparent 1px,
            transparent 11px,
            hsla(284, 43%, 48%, 0.05) 11px,
            hsla(284, 43%, 48%, 0.05) 12px,
            transparent 12px,
            transparent 32px
        ),
        linear-gradient(90deg, rgb(60, 9, 75), rgb(139, 70, 200));
}

.services::before {
    content: '';
    background-image: url('./svg/waveTop.svg');
    display: block;
    height: 10%;
    overflow: hidden;
    line-height: 0;
    width: 100%;
    background-position: top;
    transform: rotate(180deg);
}

.services::after {
    content: '';
    background-image: url('./svg/wavesBot.svg');
    display: block;
    height: 10%;
    overflow: hidden;
    line-height: 0;
    width: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.services .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 80%;
}
